import Button from "../../../Components/Button.js";
import "../../../Assets/Styles/price_section.scss";
import "aos/dist/aos.css";
import * as BoxIcons from "react-icons/bi";

const WhatsappOnly = () => {

    return (
        <div className="Card_Box">
            <div data-aos="fade-right" className="Card CardWhatsapp">
                <div className="Top">
                    <h4>Basic Plan</h4>
                </div>
                <h3 style={{ flex: 1 }}>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>1 Administrator</span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>2 Transporters</span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>Zero naira setup fee</span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>
                        1 free website
                        </span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>
                        150 Naira per shipment
                        </span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>
                        3 Question Template
                        </span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>
                        Intracity shipment types only
                        </span>
                    </div>
                </h3>
                {/* <a
                    href="https://app.parcelflow.io"
                    target="_blank"
                    rel="noreferrer"
                >
                    <Button name="GET BASIC PLAN" />
                </a> */}
            </div>
            <div data-aos="fade-right" className="Card CardWhatsapp">
                <div className="Top">
                    <h4>Standard Plan</h4>
                </div>
                <h3 style={{ flex: 1 }}>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>3 Administrators</span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>10 Transporters</span>
                    </div>
                    {/* <div style={{display:"flex"}}>
                                <BoxIcons.BiCheck style={{color:"#008561", marginTop:3, fontSize:20, width:24}}/>
                                <span style={{color:"#74788d"}}>Up to 500 shipments</span>
                            </div> */}
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>
                            100,000 naira setup fee
                        </span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>
                        150 Naira per shipment
                        </span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>Custom Question Template</span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>Customize SMS Sender</span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>
                            All shipment type
                        </span>
                    </div>
                </h3>
                {/* <a
                    href="https://app.parcelflow.io"
                    target="_blank"
                    rel="noreferrer"
                >
                    <Button name="GET STANDARD PLAN" />
                </a> */}
            </div>
            <div data-aos="fade-right" className="Card CardWhatsapp">
                <div className="Top">
                    <h4>PRO Plan</h4>
                </div>
                <h3 style={{ flex: 1 }}>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>Unlimited Admins</span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>Unlimited Transporters</span>
                    </div>
                    {/* <div style={{display:"flex"}}>
                                <BoxIcons.BiCheck style={{color:"#008561", marginTop:3, fontSize:20, width:24}}/>
                                <span style={{color:"#74788d"}}>Up to 500 shipments</span>
                            </div> */}
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>
                        Custom Question Template
                        </span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>
                        All shipment types
                        </span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>300,000 naira setup fee</span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>300 Naira per shipment</span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>
                            Advanced AI
                        </span>
                    </div>
                </h3>
                {/* <a
                    href="https://app.parcelflow.io"
                    target="_blank"
                    rel="noreferrer"
                >
                    <Button name="GET PREMIUM PLAN" />
                </a> */}
            </div>
            <div data-aos="fade-right" style={{border:"none",boxShadow:"none"}} className="Card CardWhatsapp"></div>

        </div>
    );
};

export default WhatsappOnly;
