import React, {useEffect} from "react";
import AboutHeroSection from "./Component/About_Hero";
import CounterSection from "./Component/Counter_section";
import ApproachSection from "./Component/Approach";
import ValueSection from "./Component/Value_Section";
import BandSection from "./Component/Band_section";


const AboutPage = () => {
    useEffect(() => {
        document.title = 'About Us - Parcelflow';
    }, []);

    return ( 
        <div>
            <AboutHeroSection/>
            <CounterSection/>
            <ApproachSection/>
            <ValueSection/>
            <BandSection/>
        </div>
     );
}
 
export default AboutPage;