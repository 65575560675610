import "../../../Assets/Styles/ecommerce_solution.scss"
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";

const SolutionHireSection = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

    return (
        <div className="Recruiting_fill_hire">
        <div data-aos="fade-up" className="Box">
        <h3>Send Automated Status Notification</h3>
            <p>The system allows the sending of automated notifications to customers regarding their delivery status, such as telling them when the driver will arrive at their location. This feature allows the following:</p>
            <ul>
                <li>Know the estimated time of arrival (ETA) and estimated time of pick-up (ETP) </li>
                <li>Improved delivery experience</li>
                <li>Customers can monitor their status of delivery on  thei</li>
            </ul>
        </div>
        <div data-aos="fade-in" className="BoxE img"></div>
    </div>
    );
}
 
export default SolutionHireSection;