import "../../../Assets/Styles/price_section.scss"
import Button from "../../../Components/Button"
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";


const PriceOrientedSection = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);


    return (
        <div className="Oriented">
         <div className="Img_Box"></div>
         <div className="Box">
           <div data-aos="fade-up" className="Box_Detail">
           <h4>OUR APPROACH</h4>
            <h3>No Hidden Charges</h3>
            <p>Our pricing for all service types are simple<br/> with no hidden charges.<br/>
                Contact us to select a plan and get started..</p>
            <Button name="FIND OUT MORE"/>
           </div>
         </div>
        </div>
    );
}
 
export default PriceOrientedSection;