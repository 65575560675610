import Button from "../../../Components/Button.js";
import "../../../Assets/Styles/price_section.scss";
import "aos/dist/aos.css";
import * as BoxIcons from "react-icons/bi";

const WebMobile = () => {

    return (
        <div className="Card_Box">
            <div data-aos="fade-right" className="Card">
                <div className="Top">
                    <h4>Basic Plan</h4>
                    <h2>₦100,000</h2>
                    <h5>per month</h5>
                </div>
                <h3 style={{ flex: 1 }}>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>1 Administrator</span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>5 Transporters</span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>Up to 200 shipments</span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>
                            Additional shipment cost 100naira
                        </span>
                    </div>
                </h3>
                <a
                    href="https://app.parcelflow.io"
                    target="_blank"
                    rel="noreferrer"
                >
                    <Button name="GET BASIC PLAN" />
                </a>
            </div>
            <div data-aos="fade-right" className="Card">
                <div className="Top">
                    <h4>Standard Plan</h4>
                    <h2>₦200,000</h2>
                    <h5>per month</h5>
                </div>
                <h3 style={{ flex: 1 }}>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>5 Administrators</span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>20 Transporters</span>
                    </div>
                    {/* <div style={{display:"flex"}}>
                                <BoxIcons.BiCheck style={{color:"#008561", marginTop:3, fontSize:20, width:24}}/>
                                <span style={{color:"#74788d"}}>Up to 500 shipments</span>
                            </div> */}
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>
                            Additional shipment cost NGN90
                        </span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>
                            1000 Whatsapp Conversations
                        </span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>SMS and Emails</span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>Customize SMS Sender</span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>
                            Web & Mobile App Maintenance
                        </span>
                    </div>
                </h3>
                <a
                    href="https://app.parcelflow.io"
                    target="_blank"
                    rel="noreferrer"
                >
                    <Button name="GET STANDARD PLAN" />
                </a>
            </div>
            <div data-aos="fade-right" className="Card">
                <div className="Top">
                    <h4>Premium Plan</h4>
                    <h2>₦300,000</h2>
                    <h5>per month</h5>
                </div>
                <h3 style={{ flex: 1 }}>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>10 Administrators</span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>Unlimited Transporters</span>
                    </div>
                    {/* <div style={{display:"flex"}}>
                                <BoxIcons.BiCheck style={{color:"#008561", marginTop:3, fontSize:20, width:24}}/>
                                <span style={{color:"#74788d"}}>Up to 500 shipments</span>
                            </div> */}
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>
                            Additional shipment cost NGN60
                        </span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>
                            3000 Whatsapp Conversations
                        </span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>SMS and Emails</span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>Customize SMS Sender</span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>
                            Web & Mobile App Maintenance
                        </span>
                    </div>
                </h3>
                <a
                    href="https://app.parcelflow.io"
                    target="_blank"
                    rel="noreferrer"
                >
                    <Button name="GET PREMIUM PLAN" />
                </a>
            </div>

            <div data-aos="fade-right" className="Card">
                <div className="Top">
                    <h4>Enterprise Plan</h4>
                    <h2>Custom</h2>
                    <h5>per month</h5>
                </div>
                <h3 style={{ flex: 1 }}>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>
                            Unlimited Administrator
                        </span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>Unlimited Transporters</span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>Unlimited shipments</span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>
                            Additional shipment cost NGN50
                        </span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>
                            5000 Whatsapp Conversations
                        </span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>
                            Unlimited SMS and Emails
                        </span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>Customize SMS Sender</span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>
                            Custom Mobile & Web Application
                        </span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>API Integration</span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>Custom plugins</span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>
                            Weekly & Monthly Reports
                        </span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <BoxIcons.BiCheck
                            style={{
                                color: "#008561",
                                marginTop: 3,
                                fontSize: 20,
                                width: 24,
                            }}
                        />
                        <span style={{ color: "#74788d" }}>
                            Additional income from ads*
                        </span>
                    </div>
                </h3>
                <Button name="CONTACT US" />
            </div>
        </div>
    );
};

export default WebMobile;
