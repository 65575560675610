import Button from "../../../Components/Button"
import { Link } from "react-router-dom";
import {useEffect, useState} from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import {Markup} from "interweave";

const BlogTopSection = () => {
    const [blogCategories, setBlogCategories] = useState([]);

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

    const newData = {}

    const getBlogDetailsURL = "https://parcelflow-web.mylogisticsapp.com/v1/actions/get-all-blog-categories.php";

    console.log(newData);

    useEffect(() => {
        axios
            .post(getBlogDetailsURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(response["data"]);
                setBlogCategories(response["data"])
            });
    }, []);
    return ( 
        <div className="Top_Section">
            <h1 data-aos="fade-up">All articles</h1>
           <div className="inner-box">
            <div className="box">
                <h5>Filter by topic</h5>
                <select name="" id="">
                    <option value="all-topic">All Topics</option>
                    {blogCategories.map((category) => {
                        return (
                            <option key={category.id} value={category.id}>{category.name}</option>
                        );
                    })}
                </select>
            </div>
            <div className="box">
            <Button name="apply filters"/>
            <Link>Clear</Link>
            </div>
           </div>
        </div>
     );
}
 
export default BlogTopSection;