import React from "react";
import AppBarHeader from "./Component/AppBar.js"
import ParcelFlowNotification from "./Component/NotificationBar.jsx";

const HeaderPage = () => {
    return ( 
        <div className="Header_Wrappers">
        <ParcelFlowNotification/>
        <AppBarHeader />
        </div>
     );
}
 
export default HeaderPage;