import "../../Assets/Styles/blog.scss"
import {useParams} from "react-router-dom";
import Data from "../Blogs/components/data"
import {Fragment, useState} from "react";
import DynamicTop from "./components/dynamic_top";
import DynamicBottomSection from "./components/dynamic_bottom";
import DynamicFloatSection from "./components/float_section";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";

const SingleBlogPage = () => {
    const [title, setTitle] = useState("");
    const [category, setCategory] = useState("");
    const [content, setContent] = useState("");
    const [featureImageURL, setFeatureImageURL] = useState("");
    const [authorName, setAuthorName] = useState("");
    const [authorBio, setAuthorBio] = useState("");
    const [publishedDate, setPublishedDate] = useState("");
    const [authorProfileImageURL, setAuthorProfileImageURL] = useState("");
    const [linkedInProfileURL, setLinkedInProfileURL] = useState("");
    const [twitterProfileURL, setTwitterInProfileURL] = useState("");

    useEffect(() => {
        // AOS.init({duration:2000});
        // AOS.refresh();
      }, []);
    const param = useParams();
    const blog = Data[param.id-1];

    const newData = {
        "url_identifier": param["id"]
    };
    const getBlogDetailsURL = "https://parcelflow-web.mylogisticsapp.com/v1/actions/get-blog-post.php";

    useEffect(() => {
        axios
            .post(getBlogDetailsURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(response);
                setFeatureImageURL(response["data"]["feature_image_url"]);
                setTitle(response["data"]["title"]);
                setCategory(response["data"]["category"]);
                setContent(response["data"]["content"]);
                setAuthorName(response["data"]["author_name"]);
                setAuthorBio(response["data"]["author_bio"]);
                setPublishedDate(response["data"]["date_published"]);
                setAuthorProfileImageURL(response["data"]["author_profile_image_url"]);
                setTwitterInProfileURL(response["data"]["twitter_profile_url"]);
                setLinkedInProfileURL(response["data"]["linkedin_profile_url"]);

                document.title = response["data"]["title"] + " - The Parcelflow Blog";
            });
    }, []);

    return (
        <Fragment>
            <DynamicTop img={featureImageURL} />
            <DynamicFloatSection title={title} featureImageURL={featureImageURL} />
            <DynamicBottomSection content={content} authorName={authorName} linkedInProfileURL={linkedInProfileURL}
                                  authorBio={authorBio} authorProfileImageURL={authorProfileImageURL}
                                  twitterProfileURL={twitterProfileURL} publishedDate={publishedDate} category={category}/>
        </Fragment>
    );
}
 
export default SingleBlogPage;