const TermsServiceTopPage = () => {
    return (
        <div className="Terms_Top">
            <div className="container">
                <h2>Terms And Conditions</h2>
                <p>Please read these Terms and Conditions, which set forth the legally binding terms and conditions between you and Parcelflow. It governs your access to and the use of the website and all related applications and services.</p>
                <p>Parcelflow is a company registered under law in the Federal Republic of Nigeria.</p>
            </div>
        </div>
    );
}
 
export default TermsServiceTopPage;