import "../../../Assets/Styles/restaurant_solution.scss"
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";

const SolutionHireSection = () => {
    useEffect(() => {
        AOS.init({duration:2000});
        AOS.refresh();
      }, []);

    return (
        <div className="Recruiting_fill_hire">
        <div data-aos="fade-up" className="Box">
        <h3>AI integration available for website</h3>
            <p>We also offer AI integrations, making it easy for their cutomers to have access to your services via social media, without having to go through an app. </p>
        </div>
        <div data-aos="fade-in" className="BoxR img"></div>
    </div>
    );
}
 
export default SolutionHireSection;