import "../../../Assets/Styles/ecommerce_solution.scss"
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";

const SolutionCustomService = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

    return (
        <div className="Talent_Service_fill">
            <div data-aos="fade-in" className="BoxE img"></div>
            <div data-aos="fade-up" className="Box">
                <h3>Avoid delivery mistakes with our sophisticated proof of delivery service</h3>
                <p>The system provides you with real-time status of the delivery and helps you and the end-customer to know whether the products are delivered or not. Th feature enables the following:</p>
                <ul>
                    <li>Provides accurate real-time data regarding the successful delivery of a package.</li>
                   <li>Proof of delivery improves driver accountability and reporting.</li>
                   <li>Improved accountability for each delivery.</li>
                    <li>Remove the need for any manual delivery paperwork.</li>
                    <li>Allow admins and dispatchers to view all the delivery information once it’s submitted.</li>
                </ul>
            </div>
        </div>
    );
}
 
export default SolutionCustomService;