import "../../../Assets/Styles/ecommerce_solution.scss"
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";


const SolutionRecruitingSection = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);


    return (
        <div className="Recruiting_fill">
            <div data-aos="fade-up" className="Box">
            <h3>Enjoy Fast Delivery and Priority Pickup </h3>
                <p>The system allows you enjoy the benefit of requesting a priority pick-up service. This means that you can process a special delivery service earlier than the proposed time a delivery is supposed to be completed.</p>
            </div>
            <div data-aos="fade-in" className="BoxE img"></div>
        </div>
    );
}
 
export default SolutionRecruitingSection;