import "../../../Assets/Styles/about_hero.scss"
import diamond from "../../../Assets/Svg/diamond.svg"
import user from "../../../Assets/Svg/users.svg"
import cup from "../../../Assets/Svg/cup.svg"
import graph from "../../../Assets/Svg/graph.svg"
import ship from "../../../Assets/Svg/ship.svg"
import phone from "../../../Assets/Svg/phone.svg"
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";



const ValueSection = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);


    return ( 
       <div className="Value-fill">
        <div className="Top">
            <h1>Our values</h1>
            <div className="Card-Box">
                <div data-aos="fade-up" className="Card">
                    <img src={cup} alt="avatar" />
                    <h4>Innovation</h4>
                    <p>We are committed to constantly innovating our service offerings to ensure world class solutions.</p>
                </div>
                <div data-aos="fade-up" className="Card">

                    <img src={diamond} alt="avatar" />
                    <h4>Transparency</h4>
                    <p>Our methods of operation thrives on openness, communication, and accountability.</p>
                </div>
                <div data-aos="fade-up" className="Card">
                    <img src={user} alt="avatar" />
                    <h4>Customer Oriented</h4>
                    <p>We ensure that our business goals as an orgaization aligns with the business goals of our customers</p>
                </div>
            </div>
        </div>
       </div>
    );
}
 
export default ValueSection;