import {Fragment, useEffect} from "react";
import "../../Assets/Styles/Policy.scss"
import PrivacyPolicyBody from "./Components/PolicyBody";
import PrivacyPolicyTopSection from "./Components/PrivacyTop";

const PrivacyPolicyPage = () => {
    useEffect(() => {
        document.title = 'Privacy Policy - Parcelflow';
    }, []);

    return (
        <Fragment>
        <PrivacyPolicyTopSection/>
        <PrivacyPolicyBody/>
        </Fragment>
    );
}
 
export default PrivacyPolicyPage;