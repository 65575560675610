import "../../../Assets/Styles/testimonial.scss"
import img1 from "../../../Assets/Image/tes_img_1.jpg";
import img2 from "../../../Assets/Image/tes_img_2.jpg";
import img3 from "../../../Assets/Image/tes_img_3.jpg";
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";


const Testimonial = () => {

    useEffect(() => {
        AOS.init({duration:2000});
        AOS.refresh();
      }, []);

    return ( 
       <div  className="Testimonial-fill">
        <h6 data-aos="fade-up">OUR CLIENTS</h6>
        <h2 data-aos="fade-up">Testimonials</h2>
        <div data-aos="fade-up" className="Card_box">
            <div  className="Card">
                <p>“When we started our business, we started manually. We had to use paper and pen. But as we grew, it became clear we needed some sort of system. Thankfully, we found Parcelflow and it's been sweet sailing since then.”</p>
                <div className="profile_details">
                    {/*<img src={img1} alt="avatar" />*/}
                    <div className="details">
                        <h5>Abigail</h5>
                        <p>Customer</p>
                    </div>
                </div>
            </div>
            <div className="Card">
                <p>"Our team switched over to Parcelflow after our developers failed to deliver a functional system. We were skeptical at first but upon our first try, we knew we had found the ultimate solution for our logistics problems."</p>
                <div className="profile_details">
                    {/*<img src={img2} alt="avatar" />*/}
                    <div className="details">
                        <h5>Omotayo</h5>
                        <p>Customer</p>
                    </div>
                </div>
            </div>
            <div className="Card">
                <p>"Running both international and interstate logistics businesses comes with it's own special problems. Running in Lagos is another hassle. However, Parcelflow and the team has helped us manage our operations"</p>
                <div className="profile_details">
                    {/*<img src={img3} alt="avatar" />*/}
                    <div className="details">
                        <h5>Abike</h5>
                        <p>Customer</p>
                    </div>
                </div>
            </div>
        </div>
       </div>
     );
}
 
export default Testimonial;