import "../../../Assets/Styles/logistics_solution.scss"
import Button from "../../../Components/Button.js"
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";


const SolutionTalentSection = () => {
    useEffect(() => {
        AOS.init({duration:2000});
        AOS.refresh();
      }, []);


    return (
        <div className="Talent_fill">
            <div data-aos="fade-in" className="Box img"></div>
            <div data-aos="fade-up" className="Box">
                <h3>Receiver customer orders easily using your preferred social channel</h3>
                <p>With the Parcelflow whatsapp inntegration, customers can easily have access to your services via social media, without having to go through the app for quick enquiries. This enables the following:</p>
                <ul>
                    <li>Fast and easy connection with customers</li>
                    <li>Timely response to customers</li>
                    <li>Customers can determine cost for their delivery order via socialmedia</li>
                    <li>You can also start, process shipment and finish transaction with a customer via whatsapp!</li>
                </ul>
            </div>
        </div>
    );
}
 
export default SolutionTalentSection;