import "../../../Assets/Styles/ecommerce_solution.scss"
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";


const SolutionTalentSection = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);


    return (
        <div className="Talent_fill">
            <div data-aos="fade-in" className="BoxE img"></div>
            <div data-aos="fade-up" className="Box">
                <h3>Access to Both Local and International Logistics Operators </h3>
                <p>Using the Parcelflow app to manage your logistics automatically gives you access to over hundreds of logistics operators to help you process both local and international shipments</p>
            </div>
        </div>
    );
}
 
export default SolutionTalentSection;