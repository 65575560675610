import "../../../Assets/Styles/price_section.scss"
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";


const CommentCardSection = () => {
  useEffect(() => {
    AOS.init({duration:2000});
    AOS.refresh();
  }, []);


    return (
        <div className="Comment_Card">
            <div className="Container">
                <div className="Detail_Box">
                 <div data-aos="fade-up" className="Box">
                   <h1>“</h1>
                   <p>ParcelFlow pricing is very convenient <br/>and we have had no issues so far with the service <br/>we are getting in return .</p>
                   <div className="Content">
                    <h4>Ayo Emakhiomhe</h4>
                    <h5>520 Logistics</h5>
                   </div>
                 </div>
                 <div className="Img_Box"></div>
                </div>
            </div>
        </div>
    );
}
 
export default CommentCardSection;