import "../../../Assets/Styles/restaurant_solution.scss"
import Button from "../../../Components/Button.js"
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";

const SolutionCustomService = () => {
    useEffect(() => {
        AOS.init({duration:2000});
        AOS.refresh();
      }, []);

    return (
        <div className="Talent_Service_fill">
            <div data-aos="fade-in" className="BoxR img"></div>
            <div data-aos="fade-up" className="Box">
                <h3>Automated Delivery Prices</h3>
                <p>The parcelflow app automatically allows your customers to check the cost of shipping their packages from the entered source address to the destination.</p>
            </div>
        </div>
    );
}
 
export default SolutionCustomService;