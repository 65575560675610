import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";
import "../../../Assets/Styles/ecommerce_solution.scss"


const SolutionHeroSection = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
        document.title = 'Ecommerce Business Solutions - Parcelflow';
      }, []);


    return ( 
        <div className="Solution_Hero_Fill">
            <div className="Container">
                <div data-aos="fade-up" className="Box">
                    {/*<h6>More solutions</h6>*/}
                    <h2>Improve Your Ecommerce Courier Operations</h2>
                </div>
                <div data-aos="fade-in" className="BoxE img"></div>
            </div>
        </div>
     );
}
 
export default SolutionHeroSection;