import "../../../Assets/Styles/logistics_solution.scss"
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";

const SolutionRecruiterManager = () => {
    useEffect(() => {
        AOS.init({duration:2000});
        AOS.refresh();
      }, []);

    return (
        <div className="Talent_Service_Manager">
            <div data-aos="fade-in" className="Box img"></div>
            <div data-aos="fade-up" className="Box">
                <h3>Don't Lose Your Money. Track All Payment Activities</h3>
                <ul>
                    <li>Easily keep track of pending payments</li>
                   <li>Easily track cases where a refund is required</li>
                </ul>
            </div>
        </div>
    );
}
 
export default SolutionRecruiterManager;