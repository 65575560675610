import {Link} from "react-router-dom";
import Button from "../../../Components/Button"
import asterisk from "../../../Assets/Image/asterisk.svg"
import {useEffect, useState} from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import {Markup} from "interweave";

const DynamicBottomSection = ({content, category, authorName, authorBio, authorProfileImageURL,
                                  twitterProfileURL, linkedInProfileURL, publishedDate}) => {
    useEffect(() => {
        AOS.init({duration: 2000});
        AOS.refresh();
    }, []);

    return (
        <div className="Bottom_Fill">
            <div className="Container">
                <div data-aos="fade-up" className="Left_Box">
                    <div className="border_line"></div>
                    <Markup content={content}/>
                    {/*<small>Feel inspired by Jay’s story? Learn how you can be a great Talent Maker, here.</small>*/}
                    {/*<Button name="Learn now"/>*/}
                    <div className="Mobile_Banner">
                        <div className="Form_Box">
                            <h4>Subscribe to our newsletter</h4>
                            <p>Our bi-weekly newsletter full of inspiration, podcasts, trends and news.</p>
                            <label htmlFor="mail"> <img src={asterisk} alt="asterisk"/> Email address</label>
                            <input type="email" id="mail"/>
                            <Button name="Subscribe"/>
                        </div>
                        <div className="list_box">
                            <h6>Category:</h6>
                            <ul>
                                <li>
                                    <Link>{category}</Link>
                                </li>
                            </ul>
                            <small>{publishedDate}</small>
                        </div>
                    </div>
                    <div className="bottom_box">
                        <img src={authorProfileImageURL} alt={authorName}/>
                        <p>
                            <span>{authorName}</span> {authorBio} Find {authorName} on
                            <a target="_blank" href={twitterProfileURL}> Twitter</a> and
                            <a target="_blank" href={linkedInProfileURL}> LinkedIn</a>.
                        </p>
                    </div>
                </div>
                <div className="Right_Box">
                    <div data-aos="fade-up" className="list_box">
                        <h6>Filed under:</h6>
                        <ul>
                            <li>
                                <Link>{category}</Link>
                            </li>
                        </ul>
                        <small>{publishedDate}</small>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DynamicBottomSection;