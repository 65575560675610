const ContactUsTopPage = () => {
    return (
        <div className="Contact_Top">
            <div className="container">
                <h2>Contact Us</h2>
                <p>We look forward to working with you to meet your growth goals!</p>
            </div>
        </div>
    );
}
 
export default ContactUsTopPage;