import Button from "../../../Components/Button.js";
import "../../../Assets/Styles/price_section.scss";
import AOS from "aos";
import { useEffect, useState } from "react";
import "aos/dist/aos.css";
import * as BoxIcons from "react-icons/bi";
import WebMobile from "./WebMobile.jsx";
import WhatsappOnly from "./WhatsappOnly.jsx";

const PricePlanSection = () => {
    const [togglePlan, setTogglePlan] = useState("Web-Mobile");
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    const handleTogglePlan = () => {
        if (togglePlan === "Web-Mobile") {
            setTogglePlan("WhatsappOnly");
        } else {
            setTogglePlan("Web-Mobile");
        }
    };

    return (
        <div className="Plan_Section">
            <div className="Container">
                <h3 className="header_plan_text">Plans</h3>

                <div className="custom-row">
                    <div
                        className="toggle-contain"
                        onClick={handleTogglePlan}
                    >
                        <div
                            className={`toggle-text ${togglePlan === "Web-Mobile" && "active"
                                }`}
                        >
                            Web and Mobile
                        </div>
                        <div
                            className={`toggle-text ${togglePlan === "WhatsappOnly" && "active"
                                }`}
                        >
                            Whatsapp Only
                        </div>
                        <div
                            className={` toggle-bar-contain
                        ${togglePlan === "Web-Mobile" ? "start-0" : "start-50"
                                }`}
                        >
                            <div className="toggle-bar"></div>
                        </div>
                    </div>
                </div>

                {
                    togglePlan === "Web-Mobile" ? (
                        <WebMobile />
                    ) : (
                        <WhatsappOnly />
                    )
                }
            </div>
        </div>
    );
};

export default PricePlanSection;
