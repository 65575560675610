import "../../../Assets/Styles/conference.scss"
import Button from "../../../Components/Button.js"
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";
import Btn from "../../../Components/Button.js";

const ConferenceHealthSection = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);


    return (
        <div className="Health_fill">
            <div data-aos="fade-right" className="Box"></div>
            <div data-aos="fade-up" className="Box">
                <h3>Building a profitable logistics with Parcelflow</h3>
                <p>As an attendee, you get all year support round the clock on how to improve improve sales, structure your business increase market penetration. Our team of experts, with years of experience will always be glad to hear your questions and field your with appropriate answers</p>
                <a target="_blank" href="https://paystack.com/pay/parcelflow-webinar">
                    <Btn name="REGISTER NOW" />
                </a>
            </div>
        </div>
    );
}
 
export default ConferenceHealthSection;