import "../../../Assets/Styles/conference.scss"
import Button from "../../../Components/Outline_btn"
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";
import Btn from "../../../Components/Button";

const ConferenceExpectSection = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);


    return (
        <div className="Expect_fill">
            <div className="Container">
            <div data-aos="fade-up" className="Top">
                    <h2>What to expect</h2>
                    <p>With key topics like structuring your logistics, increasing sales and employing new growth strategies, this Parcelflow would show participants how to start and grow a small logistics business to a thriving one</p>
                </div>
                <div data-aos="fade-up" className="Card">
                    <div className="Card_Detail">
                    <div className="img"></div>
                    <h3>Day 1 – Sat, Mar 11</h3>
                    <p>Primarily for starters in logistics</p>
                    <ul>
                        <li>Introduction to logistics in Nigeria</li>
                        <li>How to operate logistics from the comfort of your home</li>
                        <li>Be profitable without employing or owning assets</li>
                        <li>Grow without boundaries</li>
                        <li>Become a major player in the logistics industry</li>
                    </ul>
                    <a target="_blank" href="https://paystack.com/pay/parcelflow-webinar">
                        <Btn name="REGISTER NOW" />
                    </a>

                    </div>
                    <div className="Card_Detail">
                    <div className="img"></div>
                    <h3>Day 2 – Sat, Mar 18</h3>
                    <p>Primarily for experienced logistics businesses</p>
                    <ul>
                        <li>Introduction to Parcelflow</li>
                        <li>Setting up your organisational structure with Parcelflow</li>
                        <li>Setting up your operational structure with Parcelflow</li>
                        <li>Increase market penetration</li>
                        <li>Pricing policy and customer policy</li>
                    </ul>
                        <a target="_blank" href="https://paystack.com/pay/parcelflow-webinar">
                            <Btn name="REGISTER NOW" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default ConferenceExpectSection;