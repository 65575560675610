import "../../../Assets/Styles/logistics_solution.scss"
import Button from "../../../Components/Button.js"
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";

const TransporterLocationSection = () => {
    useEffect(() => {
        AOS.init({duration:2000});
        AOS.refresh();
      }, []);

    return (
        <div className="Talent_Service_fill">
            <div data-aos="fade-in" className="Box img"></div>
            <div data-aos="fade-up" className="Box">
                <h3>Track Your Shipment and Trasporters With Ease </h3>
                <p>Parcelflow allows you to easily monitor all your delivery and assigned transporters either via the web or through the mobile. The Parcelflow helps you do the following :</p>
                <ul>
                    <li>Track the exact location of each of your transporter </li>
                   <li>Determine what shipment they are currently processing at any given time</li>
                   <li>Know their next pickups and deliveries</li>
                </ul>
            </div>
        </div>
    );
}
 
export default TransporterLocationSection;