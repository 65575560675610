import { useState } from "react";
import { Link } from "react-router-dom";

const ParcelFlowNotification = () => {
    const [Show,setShow]=useState(true)


    const closehandle=()=>{
        setShow(false)
    }

    return (
        <div className={`${Show===true ? 'Notification_Div' : 'No_active'}`}>
        <h5>Find out how to run a profitable logistics business at the Parcelflow Connect Webinar on March 25th.</h5>
        <div className="Reg_Wrap">
        <a target="_blank" href="https://connect.parcelflow.io/">Register now</a>
        <svg class="css-1k6cidy e1koaidd2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 12"><path d="M6.26154.536743l-.85527.820367 3.84 3.97963H.676086v1.32655H9.24627l-3.84 3.97961.85527.8204 5.06186-5.28874v-.34909L6.26154.536743z"></path></svg>
        </div>
        <div className="Close_Svg">
        <svg onClick={closehandle} width="14" height="14" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" role="graphics-symbol" aria-labelledby="x-icon-title-id" aria-hidden="false" fill="" ><title id="x-icon-title-id">x icon</title><path d="M9.0001 8.0626L13.4501 3.6126C13.7501 3.3126 13.7501 2.8626 13.4501 2.5626C13.1501 2.2626 12.7001 2.2626 12.4001 2.5626L7.9501 7.0126L3.5001 2.5126C3.2001 2.2126 2.7501 2.2126 2.4501 2.5126C2.1501 2.8126 2.1501 3.2626 2.4501 3.5626L6.9001 8.0126L2.4501 12.4626C2.1501 12.7626 2.1501 13.2126 2.4501 13.5126C2.6001 13.6626 2.8001 13.7126 3.0001 13.7126C3.2001 13.7126 3.4001 13.6626 3.5501 13.5126L8.0001 9.0626L12.4501 13.5126C12.6001 13.6626 12.8001 13.7126 13.0001 13.7126C13.2001 13.7126 13.4001 13.6626 13.5501 13.5126C13.8501 13.2126 13.8501 12.7626 13.5501 12.4626L9.0001 8.0626Z"></path></svg>
        </div>
        </div>
    );
}
 
export default ParcelFlowNotification;