import "../../Assets/Styles/blog.scss"
import {Fragment, useEffect, useState} from "react";
import BlogTopSection from "./components/blog_top_section";
import {Link} from "react-router-dom";
import axios from "axios";
import {Markup} from "interweave";


const BlogPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [blogposts, setBlogPosts] = useState([]);

    const newData = {}

    const getBlogDetailsURL = "https://parcelflow-web.mylogisticsapp.com/v1/actions/get-all-blog-posts.php";

    useEffect(() => {
        document.title = 'Blog - Parcelflow';

        axios
            .post(getBlogDetailsURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                setBlogPosts(response["data"]["data"]);
                setIsLoading(false);
            });
    }, []);

    return (
        isLoading === true ? <div></div>:
        <Fragment>
            <BlogTopSection/>
            {blogposts.map((blogpost) => {
                return (
                    <div key={blogpost.id} className="Tab_Conatainer">
                        <div data-aos="fade-up" className="Box">
                            <div style={{display:"flex", flexDirection: "row"}}>
                                <h6>{blogpost.category_name}</h6>
                                <span className="date_published">
                                    {blogpost.date_published}
                                </span>
                            </div>
                            <Link to={`/blog/${blogpost.url_identifier}`}>{blogpost.title}</Link>
                           <Markup content={blogpost.feature_content} />
                        </div>
                        <img src={blogpost.feature_image_url} alt="img"/>
                    </div>
                );
            })}
        </Fragment>
    );
}
 
export default BlogPage;