import "../../../Assets/Styles/logistics_solution.scss"
import Button from "../../../Components/Button.js"
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";

const SolutionHireSection = () => {
    useEffect(() => {
        AOS.init({duration:2000});
        AOS.refresh();
      }, []);

    return (
        <div className="Recruiting_fill_hire">
        <div data-aos="fade-up" className="Box">
        <h3>Offer Special Priviledges With Different Rates</h3>
            <p>You can set discounted prices for certain customers based on patronage and bulky delivery for instance:</p>
            <ul>
                <li>Certain customers such as regular ones, can have payment discount </li>
                <li>With the Parceflow app. you can set payment plans and timing. This means that you can offer special payment process for certain customers</li>
            </ul>
        </div>
        <div data-aos="fade-in" className="Box img"></div>
    </div>
    );
}
 
export default SolutionHireSection;