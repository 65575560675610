import "../../../Assets/Styles/about_hero.scss"
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";


const CounterSection = () => {
    useEffect(() => {
        AOS.init({duration:2000});
        AOS.refresh();
      }, []);


    return ( 
       <div className="Counter-fill">
         <div className="Builder">
            <div data-aos="fade-left" className="Box">
                <h2>We're building the operating system of global logistics</h2>
            </div>
            <div data-aos="fade-left" className="Box">
                <p>We have created a system that helps to access the best local & international shipping partners, allowing logistics business owners scale across the continent</p>
            </div>
         </div>
       </div>
     );
}
 
export default CounterSection;