import Button from "../../../Components/Button"
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";


const CardResult = () => {
    useEffect(() => {
        AOS.init({duration:2000});
        AOS.refresh();
      }, []);


    return ( 
        <div className="Card">
            <div data-aos="fade-up" className="Box">
                <h4>OUR APPROACH</h4>
                <h2>Innovative Performance</h2>
                <p>Our approach to builiding softwares has led us to creating innovative features like Whatsapp integration and other technological advancement which has positioned us at the fore front of logistics business solutions.</p>
                <Button name="FIND OUT MORE"/>
            </div>
            <div className="Box_img"></div>
        </div>
     );
}
 
export default CardResult;