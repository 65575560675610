import "../../../Assets/Styles/logistics_solution.scss"
import Button from "../../../Components/Button.js"
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";


const SolutionRecruitingSection = () => {
    useEffect(() => {
        AOS.init({duration:2000});
        AOS.refresh();
      }, []);


    return (
        <div className="Recruiting_fill">
            <div data-aos="fade-up" className="Box">
            <h3>Set Your Prices Based on Distance</h3>
                <p>The app is fully featured and customized to calculate the right price for deliveries. and your
                    customers can get to see the cost of their delivery request based on location, distance or delivery destination.
                </p>
            </div>
            <div data-aos="fade-in" className="Box img"></div>
        </div>
    );
}
 
export default SolutionRecruitingSection;