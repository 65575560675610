import "../../../Assets/Styles/restaurant_solution.scss"
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";


const SolutionRecruitingSection = () => {
    useEffect(() => {
        AOS.init({duration:2000});
        AOS.refresh();
      }, []);


    return (
        <div className="Recruiting_fill">
            <div data-aos="fade-up" className="Box">
            <h3>Manage Menu Inventory For Seamless Delivery </h3>
                <p>Parcelflow allows you to save time and avoid errors through proper inventory management. This helps to improve your food delivery operations and provide the perfect experience for your customers and your delivery partners.</p>
            </div>
            <div data-aos="fade-in" className="BoxR img"></div>
        </div>
    );
}
 
export default SolutionRecruitingSection;