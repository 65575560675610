import "../../../Assets/Styles/conference.scss"
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";
import Btn from "../../../Components/Button";

const ConferenceFriendSection = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);


    return (
        <div className="Friend_fill">
            <div data-aos="fade-up" className="Box">
                <h3>24/7 customer assistance all year round</h3>
                <p>As an attendee, you get all year support round the clock on how to improve improve sales, structure your business increase market penetration. Our team of experts, with years of experience will always be glad to hear your questions and field your with appropriate answers.</p>
                <a target="_blank" href="https://paystack.com/pay/parcelflow-webinar">
                    <Btn name="REGISTER NOW" />
                </a>
            </div>
            <div data-aos="fade-up" className="Box"></div>
        </div>
    );
}
 
export default ConferenceFriendSection;