import "../../../Assets/Styles/restaurant_solution.scss"
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";


const SolutionTalentSection = () => {
    useEffect(() => {
        AOS.init({duration:2000});
        AOS.refresh();
      }, []);


    return (
        <div className="Talent_fill">
            <div data-aos="fade-in" className="BoxR img"></div>
            <div data-aos="fade-up" className="Box">
                <h3>Get Access to Multiple Logistics Partners </h3>
                <p>Delivery to customers can be made easier and faster with access to multiple logistics partners to improve the customer experience. This is a great way to grow your clientele and guarantee that all of your customers can get their meal orders on time. The multiple courier options aids the following:</p>
                <ul>
                    <li>Easily reach different types of people who the other partners might not reach</li>
                    <li>Having multiple courier partners means more options for when things go wrong or don't go as planned. </li>
                    <li>Enjoy more frequent deliveries, which means faster customer service and less time spent waiting for packages to arrive at your customer's doorstep.</li>
                </ul>
            </div>
        </div>
    );
}
 
export default SolutionTalentSection;